/*
CSS para encoger la tabla con className tiny, en base al ancho de pantalla

Breakpoints extraídos de:
https://chakra-ui.com/docs/styled-system/responsive-styles

const breakpoints = {
    sm: "30em",
    md: "48em",
    lg: "62em",
    xl: "80em",
    "2xl": "96em",
}
*/

@media (min-width: 0em) {
    .tiny th {
        padding-left: 6px;
        padding-right: 6px;
        padding-top: 4px;
        padding-bottom: 4px;
    }
    .tiny td {
        padding-left: 6px;
        padding-right: 6px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
}

@media (min-width: 30em) {
    .tiny th {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .tiny td {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 6px;
        padding-bottom: 6px;
    }
}

@media (min-width: 48em) {
    .tiny th {
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .tiny td {
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 8px;
        padding-bottom: 8px;
    }
}

@media (min-width: 62em) {
    .tiny th {
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .tiny td {
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 6px;
        padding-bottom: 6px;
    }
}

@media (min-width: 96em) {
    .tiny th {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .tiny td {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 12px;
        padding-bottom: 12px;
    }
}